import { Card, Carousel, Button, Modal } from "antd";
import React, { useMemo, useState } from "react";
import TruncateMarkup from "react-truncate-markup";
import { Baby, BabyPage, DashboardInfo } from "../../interfaces";
import CustomDivider from "../CustomDivider";
import PhotoModal from "../PhotoModal/PhotoModal";
import "./Babypages.scss";
import { Link, useHistory } from "react-router-dom";
import AddBabypage from "../Icons/addBabypage";
import { useWindowSize } from "../../hooks/WindowsSize";
import CarouselSelect from "./CarouselSelect";
import { connect } from "react-redux";
import { AppState } from "../../store";

export interface Props {
  baby: DashboardInfo;
  isGuestUser?: boolean;
  hasFullAccess?: boolean;
  babies: Baby[]
}

const initialModal: {
  thumbnail: string;
  thumbnail_content: string;
  babyPage: BabyPage | null;
  visible: boolean;
} = {
  thumbnail: "",
  thumbnail_content: "",
  babyPage: null,
  visible: false,
};

const Babypages: React.FC<Props> = ({ baby, isGuestUser, hasFullAccess, babies }) => {
  const [modal, setModal] = useState({ ...initialModal });
  const history = useHistory()
  const [width] = useWindowSize();
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [count, setCount] = useState<number>(0);
  const reversedBabypages = [...baby.babyPages].reverse();

  const title: any = (index: number) => {
    if (reversedBabypages[index].event === "Custom Page") {
      return (
      <>
        <h3 className="babypage-title">{reversedBabypages[index].event}</h3>
      </>
      )
    }
    else {
      return (
        <TruncateMarkup lines={1}>
          <h3 className="babypage-title">{reversedBabypages[index].title}</h3>
          </TruncateMarkup>
      );
    }
  };

  const allBabypages = useMemo(() => {
    let allBabypages : any = []

    babies.map(baby => {
      allBabypages = [...allBabypages, ...baby.babyPages]
    })
    return allBabypages;
  }, [babies]) 

  return (
    <>
      {/* <div className="mobile-only">
        <CustomDivider text="BABYPAGES" />
      </div> */}
      <Card className="d-block dashboard-card">
        <div className={`col-12 babypage-card-title mb-3 ${width > 767 ? "mt-2" : "mt-2"}`}>
          <p></p>
          <h2>BABYPAGES</h2>
          <div onClick={() => {
            history.push("/milestones")
          }}
            className="clickable"
          >
            {isGuestUser && hasFullAccess || !isGuestUser ? <AddBabypage /> : null }
          </div>
        </div>
        {baby.babyPages.length > 0 ? (
          <>
             <Carousel
             className="babypage-carousel"
               slidesToShow={2}
               swipe
               draggable
               afterChange={(index) => {
                 if(currentIndex > 4 && index < currentIndex && Number.isInteger(currentIndex / 5)) {
                  setCount(count-1)
                 } else if (currentIndex >= 4 && index >= currentIndex && Number.isInteger(index / 5)) {
                  setCount(count + 1);
                }
                setCurrentIndex(index)
               }}
               autoplay
               autoplaySpeed={15000}
               infinite={false}
               dots={true}
               arrows={false}
             >
               {reversedBabypages.map((babyPage, index: number) => (
                 <div key={index}>
                   <div style={{ position: "relative", marginTop:"-10px" }}>
                     <img
                       className="carousel-img"
                       src={babyPage.thumbnail}
                       alt={babyPage.title}
                       onClick={() =>
                         setModal({
                           visible: true,
                           thumbnail: babyPage.thumbnail,
                           thumbnail_content: babyPage.thumbnail_content,
                           babyPage: babyPage,
                         })
                       }
                     />
                     <div className="babypage-detail">
                       {title(index)}
                       {!!babyPage.subtitle && (
                         <p className="babypage-subtitle">{babyPage.subtitle}</p>
                       )}
                     </div>
                   </div>
                 </div>
               ))}
             </Carousel>
          <div className={`row ${width > 767 ? "mt-3" : "mt-3 mx-2 mb-4"}`}>
          <div className="col-6">
            <Link to="/babypages">
              <Button type="primary" ghost shape="round" className="w-100 dashboard-button">
                View All
              </Button>
            </Link>
          </div>

          <div className="col-6">
              <Button
                type="primary"
                ghost
                shape="round"
                className="w-100 green-button dashboard-button"
                onClick={() => {
                  if(allBabypages.length >= 8) {
                    history.push("/books")
                  } else {
                    Modal.info({
                      title: "Notice",
                      content: `You need at least 8 Babypages to create a book.
                                Create a few more babypages to make your book.`,
                    });
              
                    return;
                  }
                }}
              >
                { isGuestUser ? "View Books" : "Make a Book"}
              </Button>
          </div>
        </div>
          </>
            
             
        ) : (
          <>
          <div className="row">
            <div className="col-12">
            <p className='babypages-message text-center mb-4'>
            Create your first babypage for{' '}
            {
              baby.baby_first_name
              }.
          </p>
            </div>
          </div>
            <div className="row">
              {hasFullAccess || !isGuestUser ? (
                          <div className="col-12">
                          <Link to="/milestones">
                            <Button type="primary" ghost shape="round" className="w-100 green-button dashboard-button">
                              Start a babypage
                            </Button>
                          </Link>
                        </div>
              ) : null }
        </div>
          </>
        )}
   

        
      </Card>
      {!!modal.babyPage && (
        <PhotoModal
          image={modal.thumbnail}
          content={modal.thumbnail_content}
          babyPage={modal.babyPage}
          visible={modal.visible}
          onClose={() => setModal((state) => ({ ...initialModal }))}
          hasFullAccess={hasFullAccess}
          isGuestUser={isGuestUser}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  babies: state.baby.babies,
});

export default connect(mapStateToProps)(Babypages);
