import info from '../package.json';

export const WEB_VERSION = info.version;
export const PRODUCTION = true;
export const BASE_URL = "https://api.babypage.com/"; // NEW PROD
export const PAGE_URL = "https://babypage.com/";
export const PAYPAL_URL = 'https://www.paypal.com/myaccount/autopay/connect'; // Used for paypal manage
export const clientId = '00c4123c-8b22-31ee-abcf-6a41c61ae8ae'; // Client ID
export const clientSecret = 'dc923f0e9ea876e6f6888a3ba144e5fe13a8260a';
export const apiVersion = "application/x.babypage.v1.0.0+json"; // API Version
export const storageKey = '@babypageStorage'; // This is the key on localStorage
export const highRes = { width: 3000, height: 3000 }; // HighResolution to Save
export const highResContent = { width: 2000, height: 2000 }; // HighResolution to Save
export const STRIPE_KEY = 'pk_live_raQpvv6GuOkIpNKh0t6GsySl'; // Paypal API Key
export const FACEBOOK_PIXEL = {
  name: 'BabyPage',
  id: '1930345967189621',
};
export const AMPLITUDE = {
  apiKey: '32cb1860fd904a71902dc3d1157fa6a1',
};
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCgEY8Cib5Lv4KTZVTFWqrMjcFgznyPD4I",
  authDomain: "babypageprod.firebaseapp.com",
  databaseURL: "https://babypageprod.firebaseio.com",
  projectId: "babypageprod",
  storageBucket: "babypageprod.appspot.com",
  messagingSenderId: "89420343458",
  appId: "1:89420343458:web:e382b5ca1dce92d24b4ffb",
  measurementId: "G-1ZMRZEGL71"
};
export const PAYPAL = {
  apiKey:
    'AcKhkyg_cEypwkcArJS2I-u1PMJ6K31T3TqCgflExNxsUPXOb0LjJVndfQBTOHUZaXPWX6VxhT00Udw5',
  merchantId: 'B3AQ9R3QGLSW2',
  mode: 'production',
  commit: true,
  locale: 'en_US',
  style: {
    label: 'checkout', // checkout | credit | pay | buynow | generic
    size: 'responsive', // small | medium | large | responsive
    shape: 'rect', // pill | rect
    color: 'blue', // gold | blue | silver | black
    tagline: false,
  },
  buttons: {
    monthly: 'N9A29G9RF552A',
    three_month: 'A2UE2VT95KKW2',
    one_year: 'N5P29HNJPZXUQ',
  },
};
