import amplitude from "amplitude-js";
import * as firebase from "firebase/app";
import "firebase/analytics";
import ReactPixel from "react-facebook-pixel";
import { AMPLITUDE } from "../env";
import { CartItem } from "./../interfaces/cart";
declare const pintrk: any;
declare const snaptr: any;
declare const qp: any;

/**
 * Initialize Marketing Tools
 */
export const initMarketingTools = () => {
  const visitedUrl = window.location.pathname + window.location.search;

  try {
    ReactPixel.pageView();
  } catch (error) {}

  try {
    const amplitudeInstance = amplitude.getInstance();
    amplitudeInstance.init(AMPLITUDE.apiKey);
    amplitudeInstance.logEvent("PageVisit", { url: visitedUrl });
  } catch (error) {}

  // Quora Tracking
  try {
    qp('track', 'ViewContent');
  } catch (error) {
    console.error(error);
  }

  // SnapChat Tracking
  try {
    snaptr('track', 'PAGE_VIEW');
  } catch (error) {
    console.error(error);
  }
  
  try {
    pintrk("track", "PageVisit", { url: visitedUrl });
  } catch (error) {}

  try {
    firebase.analytics().logEvent("page_view", {
      page_path: visitedUrl,
      page_title: visitedUrl
    });
  } catch (error) {
    console.error(error);
  }
};

/**
 * Track Actions
 */
export const trackAction = (action: string, data: any, custom?: boolean) => {
  try {
    !!custom
      ? ReactPixel.trackCustom(action, data.facebook)
      : ReactPixel.track(action, data.facebook);
  } catch (error) {}

  try {
    const amplitudeInstance = amplitude.getInstance();
    amplitudeInstance.init(AMPLITUDE.apiKey);
    amplitudeInstance.logEvent(action, data.amplitude);
  } catch (error) {}

  // Quora Tracking - New signUp
  if (action === "trackNewSignUp") {
    console.info("Tracking New SignUp with Quora...")

    try {
      qp('track', 'CompleteRegistration')
    } catch (error) {
      console.error(error);
    }
  }

  // Quora Tracking - New purchase
  if (action === "trackNewPurchase") {
    console.info("Tracking New Purchase with Quora...")
    
    try {
      qp('track', 'Purchase');
    } catch (error) {
      console.error(error);
    }
  }
  if(action === "CompleteRegistration") {
    // Track pinterest signup
    try {
      pintrk("track", data.pinterest.event, data.pinterest);
    } catch (error) {

    }
  } else {
    try {
      pintrk("track", action, data.pinterest);
    } catch (error) {}
  }
  try {
    firebase.analytics().logEvent(data.google.event, data.google.event === 'purchase' ? data.google.details : data.google);
  } catch (error) {
    console.error(error);
  }
};

/**
 * Marketing for purchases
 */

export const trackPurchase = (store: CartItem[], total: number) => {
  const pixelData = {
    value: total.toFixed(2),
    currency: "USD",
    contents: [] as any,
    line_items: [] as any,
    num_items: 0,
    content_type: "product_group"
  };

  store.forEach(product => {
    switch (product.type) {
      case "print":
      case "book":
        product.options.forEach(item => {
          if (item.selected) {
            pixelData.num_items += item.qty;
            pixelData.contents.push({
              id: item.sku_id,
              quantity: item.qty,
              item_price: item.price,
              description: item.description
            });
            pixelData.line_items.push({
              product_name: item.description,
              product_category:
                item.title === "Single Print" ? "Single Print" : "Book",
              product_id: item.sku_id,
              product_price: item.price,
              product_quantity: item.qty
            });
          }
        });
        break;
      case "subscription":
        product.options.forEach(item => {
          if (item.selected) {
            pixelData.num_items += item.qty;
            pixelData.contents.push({
              id: item.id,
              quantity: item.qty,
              item_price: item.price,
              description: "Subscription"
            });
            pixelData.line_items.push({
              product_name:
                item.id === "monthly"
                  ? "One Month Subscription"
                  : item.id === "three month"
                  ? "Three Month Subscription"
                  : "One Year Subscription",
              product_category: "Subscription",
              product_id: item.id,
              product_price: item.price,
              product_quantity: item.qty
            });
          }
        });
        break;
      case "giftcard":
        pixelData.num_items += product.qty;
        pixelData.contents.push({
          id: product.id,
          quantity: product.qty,
          item_price: product.total,
          description: product.subtitle
        });
        pixelData.line_items.push({
          product_name: product.subtitle,
          product_category: "Gift Card",
          product_id: product.id,
          product_price: product.total,
          product_quantity: product.qty
        });
        break;
      default:
        break;
    }
  });

  const productTrackingData = {
    facebook: pixelData,
    amplitude: pixelData,
    pinterest: pixelData,
    google: {
      event: "purchase",
      details: pixelData,
    }
  };

  trackAction("purchase", productTrackingData);
};

/**
 * Marketing during add to cart
 */
export const trackAddToCart = (product: CartItem) => {
  const productTrackingData = {
    facebook: {
      content_name: product.title,
      content_category: product.type,
      content_ids: [product.stripe_id],
      num_items: product.qty,
      value: product.total,
      currency: "USD"
    },
    amplitude: {
      id: product.stripe_id,
      name: product.title,
      category: product.type,
      number_items: product.qty,
      value: product.total,
      currency: "USD"
    },
    pinterest: {
      value: product.total,
      order_quantity: product.qty,
      currency: "USD",
      line_items: [
        {
          product_name: product.title,
          product_category: product.type,
          product_id: product.stripe_id,
          product_price: product.total,
          product_quantity: product.qty
        }
      ]
    },
    google: {
      event: "add_to_cart",
      details: `User added ${product.qty} ${product.title} to the cart`
    }
  };
  trackAction("AddToCart", productTrackingData);
};
