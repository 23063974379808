import React, { useMemo, useState, useCallback } from "react";
import { QuestionItem, QuestionAnswer, Entry } from "../../interfaces";
import { InputNumber } from "antd";
import { useEffect } from "react";

interface Props {
  questions: QuestionItem[];
  changeAnswer: (answer: QuestionAnswer) => void;
  initialHeight: number;
  initialWeight: number;
  setEntry:  React.Dispatch<React.SetStateAction<Entry>>
}

const QuestionHeight: React.FC<Props> = ({
  questions,
  changeAnswer,
  initialHeight,
  initialWeight,
  setEntry
}) => {
  const [heightAnswer, setHeightAnswer] = useState<number | undefined>(
    initialHeight
  );
  const [weightAnswer, setWeightAnswer] = useState<number | undefined>(
    initialWeight > 0 ? ~~(initialWeight / 16) : 0
  );
  const [weightAnswerOz, setWeightAnswerOz] = useState<number | undefined>(
    initialWeight > 0 ? initialWeight % 16 : 0
  );

  const [height, weight] = useMemo(
    () => [
      questions.find((q) => q.type === "height"),
      questions.find((q) => q.type === "weight"),
    ],
    [questions]
  );

  const weightValue = useCallback(
    (valueLbs: number | undefined, valueOz: number | undefined): string[] => {
      return [((valueOz || 0) + (valueLbs || 0) * 16).toString()];
    },
    []
  );

  const concatenatedHeightAnswer = (heightAnswer: number | undefined) => {
    if(heightAnswer){
    return `${height?.export.replace('[HEIGHT]', heightAnswer.toString())}`
    }

    return "0";
  }

  const concatenatedWeightAnswer = (weightAnswer: number | undefined) => {
    if(weightAnswer){
    return `${weight?.export.replace('[WEIGHTLBS]', weightAnswer.toString()).replace('[WEIGHTOZ] oz', '')}`
    }

    return "0";
  }

  const concatenatedWeightAnswerOZ = (weightAnswer: number | undefined) => {
    if(weightAnswer) {
      return `${weight?.export.replace(' [WEIGHTOZ]', weightAnswer.toString()).replace('[WEIGHTLBS] lbs', '')}`
    }
    return "0";
  }

  const concatenatedWeightAnswerBoth = (weightAnswer: number | undefined, weightAnswerOz: number | undefined) => {
    if(weightAnswer && weightAnswerOz) {
      return `${weight?.export.replace('[WEIGHTOZ]', weightAnswerOz.toString()).replace('[WEIGHTLBS]', weightAnswer.toString() )}`
    }
    return "0";
  }

  useEffect(() => {
   
    if(weightAnswer !== 0 || weightAnswerOz !== 0) {

   
    if (weight) {
      if(weightAnswer && weightAnswer > 0 && !weightAnswerOz) {
        changeAnswer({
          question_id: weight.id,
          position: !!weight.order ? weight.order : 0,
          answer_string: weightValue(weightAnswer, weightAnswerOz),
          answer: concatenatedWeightAnswer(weightAnswer),
          type: weight.type,
        });
      } else if (weightAnswer && weightAnswerOz && weightAnswer > 0 && weightAnswerOz > 0) {
        changeAnswer({
          question_id: weight.id,
          position: !!weight.order ? weight.order : 0,
          answer_string: weightValue(weightAnswer, weightAnswerOz),
          answer: concatenatedWeightAnswerBoth(weightAnswer,weightAnswerOz),
          type: weight.type,
        });
      } else {
        changeAnswer({
          question_id: weight.id,
          position: !!weight.order ? weight.order : 0,
          answer_string: weightValue(weightAnswer, weightAnswerOz),
          answer: concatenatedWeightAnswerOZ(weightAnswerOz),
          type: weight.type,
        });
      }
    }
  }

  }, [weightAnswer, weightAnswerOz])

  return (
    <div className="row justify-content-center">
      {!!height && (
        <div className="col-12 col-md-7">
          <p className="message text-start m-0">{height.question}</p>
          <label>Height (inches)</label>
          <InputNumber
            value={heightAnswer}
            placeholder="Height (inches)"
            step={0.1}
            min={0}
            max={99.9}
            onChange={(e) => {
              const value = e !== undefined && e > 99.9 ? 99.9 : e;
              setEntry((state) => ({
                ...state,
                height: !!value ? value : 0
              }));

              setHeightAnswer(value);
              changeAnswer({
                question_id: height.id,
                position: !!height.order ? height.order : 0,
                answer_string: [(value || 0).toString()],
                answer: concatenatedHeightAnswer(value),
                type: height.type,
              });
            }}
            className="w-100 my-1"
          />
        </div>
      )}
      {!!weight && (
        <div className="col-12 col-md-7">
          <p className="message text-start m-0">{weight.question}</p>
          <div className="row">
            <div className="col-6">
              <label>Weight (lbs)</label>
              <InputNumber
                value={weightAnswer}
                placeholder="Weight (lbs)"
                step={1}
                min={0}
                max={99}
                onChange={(e) => {
                  const value = e !== undefined && e > 99 ? 99 : e;
                  setEntry((state) => ({
                    ...state,
                    weight_lbs: !!value ? value : 0
                  }));
                  setWeightAnswer(value);
                }}
                className="w-100 my-1"
              />
            </div>
            <div className="col-6">
              <label>Weight (oz)</label>
              <InputNumber
                value={weightAnswerOz}
                placeholder="Weight (oz)"
                step={1}
                min={0}
                max={15}
                onChange={(e) => {
                  const value = e !== undefined && e > 15 ? 15 : e;
                  setEntry((state) => ({
                    ...state,
                    weight_oz: !!value? value : 0
                  }));
                  setWeightAnswerOz(value);
                }}
                className="w-100 my-1"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionHeight;
